export const addSpace = (place = 'bothSides', str = '', condition = true) => {
  if (condition) {
    switch (place) {
      case 'left':
        return ` ${str}`
      case 'right':
        return `${str} `
      case 'leftRight':
      default:
        return ` ${str} `
    }
  }
  return str
}

export const normalizeText = (text) => {
  const newText = text.toLowerCase()
  const sentences = newText.split('.')
  const normalizedSentences = sentences.map(
    (sentence) =>
      sentence.trim().slice(0, 1).toUpperCase() + sentence.trim().slice(1)
  )
  return normalizedSentences.join('. ')
}
