<template>
  <div
    id="g-recaptcha"
    class="g-recaptcha"
    :data-widget-id="widgetId"
    :data-sitekey="siteKey"
    data-size="invisible"
  ></div>
</template>

<script>
export default {
  name: 'Recaptcha',
  data() {
    return {
      widgetId: 0,
    }
  },
  computed: {
    siteKey: {
      get() {
        return process.env.VUE_APP__RECAPTCHA_SITE_KEY &&
          process.env.VUE_APP__RECAPTCHA_SITE_KEY !== 'NULL'
          ? process.env.VUE_APP__RECAPTCHA_SITE_KEY
          : null
      },
    },
  },
  methods: {
    execute() {
      if (window.grecaptcha && this.siteKey) {
        window.grecaptcha.execute(this.widgetId)
        return
      }
      this.$emit('verify', null)
    },
    reset() {
      window.grecaptcha.reset(this.widgetId)
    },
    render() {
      if (window.grecaptcha && this.siteKey) {
        this.widgetId = window.grecaptcha.render('g-recaptcha', {
          sitekey: this.siteKey,
          size: 'invisible',
          callback: (response) => {
            this.$emit('verify', response)
            this.reset()
          },
        })
      }
    },
  },
  mounted() {
    this.render()
  },
}
</script>

<style scoped></style>
