<template>
  <main class="page">
    <section class="section section--auth c-forget-password c-auth">
      <div class="auth-container">
        <form @submit="executeRecaptcha" novalidate>
          <div class="form__header mb-4">
            <div class="form__title mb-3">
              <h1 class="title title-lg">
                {{ $t('web.forgot_pass') }}
              </h1>
            </div>
            <div class="form__description">
              <p class="text text-md">
                {{ $t('web.email_address_associated') }}
              </p>
            </div>
          </div>
          <div class="form__body">
            <div class="form__group">
              <div class="form__item">
                <c-input
                  :ref="CONSTANTS.LOCAL.REFS.EMAIL"
                  :label="$t('web.email')"
                  v-model="inputEmail"
                  autocomplete="on"
                  :type="CONSTANTS.INPUT.META.TYPE.EMAIL"
                  :validateMethods="validateMethods[CONSTANTS.LOCAL.REFS.EMAIL]"
                ></c-input>
              </div>
            </div>
            <div class="form__group mt-3">
              <div class="form__item">
                <c-button
                  :value="$t('web.get-link')"
                  :loading="cButtonDisabled"
                ></c-button>
                <recaptcha
                  ref="recaptcha"
                  @verify="submitForgetPassword"
                ></recaptcha>
              </div>
            </div>
          </div>
          <div class="form__footer-new text-center mt-3">
            <div class="d-block text text-md">
              <c-link
                :to="{ name: $ROUTER.NAME.AUTH.LOGIN }"
                nowrap
                :label="$t('signIn')"
              />
            </div>
          </div>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import METHODS from '@/utils/Validator/METHODS'
import * as CONSTANTS from '@/CONSTANTS'
import CInput from '@/components/Input'
import CButton from '@/components/Button'
import CLink from '@/components/Link'
import Recaptcha from '@/components/Recaptcha'
import { normalizeText } from '@/utils/text'

const _CONSTANTS = {
  REFS: {
    EMAIL: 'email',
  },
}

export default {
  name: 'ForgetPassword',

  components: {
    CInput,
    CButton,
    Recaptcha,
    CLink,
  },

  data() {
    return {
      cButtonDisabled: false,
      inputEmail: '',
    }
  },

  computed: {
    CONSTANTS: {
      get() {
        return Object.assign({}, CONSTANTS, { LOCAL: _CONSTANTS })
      },
    },
    validateMethods: {
      get() {
        return {
          [_CONSTANTS.REFS.EMAIL]: [
            [METHODS.IS_NOT_EMPTY, this.$t('web.email')],
            METHODS.IS_EMAIL,
          ],
        }
      },
    },
  },

  methods: {
    executeRecaptcha(event) {
      event?.preventDefault()
      this.$refs.recaptcha.execute()
    },
    submitForgetPassword() {
      this.forgetPassword()
    },
    forgetPassword() {
      const inputs = Object.keys(this.validateMethods).reduce((acc, type) => {
        if (this.$refs[type]) {
          acc.push(this.$refs[type])
        }
        return acc
      }, [])
      const promises = inputs.map((input) => input.validate())
      Promise.all(promises)
        .then(() => {
          this.cButtonDisabled = true
          this.$api.auth
            .sentPassword(this.$refs[_CONSTANTS.REFS.EMAIL].getValue())
            .then(() => {
              this.$store.dispatch('app/showDialog', {
                title: this.$t('web.forget-password_dialog_email-link_header'),
                description: this.$t(
                  'web.forget-password_dialog_email-link_title'
                ),
              })
            })
            .catch((error) => {
              // Ужас. Нужно чтобы таких ифов не было
              if (error.errors.data) {
                this.$store.dispatch('app/showDialog', {
                  title: error.title,
                  type: 'error',
                  description: error.errors.data[0],
                })
              } else {
                const componentsMap = {
                  [_CONSTANTS.REFS.EMAIL]: this.$refs[_CONSTANTS.REFS.EMAIL],
                }
                Object.entries(error.errors).forEach((err) => {
                  componentsMap[err[0]].setError(normalizeText(err[1][0])) // Поработать с ошибками. Добавить валидатор
                })
              }
            })
            .finally(() => {
              this.cButtonDisabled = false
            })
        })
        .catch(() => {})
    },
  },
}
</script>

<style scoped lang="scss">
@import '../index';
</style>
